@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--header-height: 0px;
		--subheader-height: 0px;
	}

	html,
	body {
		@apply flex h-full flex-col scroll-smooth bg-zinc-100 text-zinc-800 dark:bg-zinc-950 dark:text-zinc-200;
	}

	html.dark {
		@apply bg-zinc-950;
	}

	#root {
		@apply flex grow flex-col;
	}

	h1 {
		@apply text-4xl text-black dark:text-white;
	}
	h2 {
		@apply text-3xl text-black dark:text-white;
	}
	h3 {
		@apply text-2xl text-black dark:text-white;
	}
	h4 {
		@apply text-xl text-black dark:text-white;
	}
	h5 {
		@apply text-lg text-black dark:text-white;
	}
	h6 {
		@apply text-base text-black dark:text-white;
	}
	p {
		@apply mb-2;
	}

	a {
		@apply transition-opacity hover:opacity-75;
	}

	[type='color'],
	[type='date'],
	[type='datetime-local'],
	[type='email'],
	[type='file'],
	[type='hidden'],
	[type='month'],
	[type='number'],
	[type='password'],
	[type='range'],
	[type='search'],
	[type='tel'],
	[type='text'],
	[type='time'],
	[type='url'],
	[type='week'],
	[multiple],
	textarea,
	select {
		@apply block;
	}

	[type='color'] {
		@apply p-0 !important;
	}

	[type='color']::-webkit-color-swatch {
		@apply appearance-none rounded border-0 !important;
	}

	code {
		@apply break-words font-mono text-pink-500;
	}
}

@layer components {
	.svg-icon {
		@apply h-[1em] w-[1em];
	}
}

@layer utilities {
	.top-scroll-offset {
		@apply top-[calc(var(--header-height)+var(--subheader-height))];
	}

	.scroll-mt-offset {
		@apply scroll-mt-[calc(var(--header-height)+var(--subheader-height))];
	}

	@layer responsive {
		[os='Windows']::-webkit-scrollbar {
			width: 0.5rem;
			height: 0.5rem;
		}

		[os='Windows']::-webkit-scrollbar-corner {
			display: none;
		}

		[os='Windows']::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0);
			border-radius: 1rem;
		}

		[os='Windows']::-webkit-scrollbar-track {
			border-radius: 1rem;
			box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0);
		}

		[os='Windows']:hover::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
		}

		.dark[os='Windows']:hover::-webkit-scrollbar-thumb {
			background-color: rgba(255, 255, 255, 0.2);
		}

		/* Hide scrollbar for Chrome, Safari and Opera */
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */
		.no-scrollbar {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}

	@keyframes keyframes-chart-fix {
		0% {
			width: 100%;
		}
		50% {
			width: calc(100% - 1px);
		}
		100% {
			width: 100%;
		}
	}

	.chart-width-fix {
		animation: keyframes-chart-fix 2s forwards;
	}
}
